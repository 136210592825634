import React from "react";
import {
    Grid,
    List, ListItem, ListItemText, makeStyles
} from "@material-ui/core";
import Layout from "../components/layout";
import { useIntl, Link } from "gatsby-plugin-intl"
import Typography from "@material-ui/core/Typography";

const style = makeStyles((theme) => ({
    centred: {
        marginLeft: "2rem"
    },
    spacer: {
        marginBottom: "2rem"
    },
    spacer1: {
        marginBottom: "1rem"
    },

}))

const Gracias = ({ location }) => {
    const intl = useIntl();
    const classes = style();
    let data = ""
    if (typeof window != "undefined") {
        data = location.state['data'][0];
        console.log("data", data)
    }

    const sector = decodeURI(data.sector)

    return (
        <Layout>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={"h5"} component={"h1"} className={classes.spacer}>{intl.formatMessage({ id: "rescod" }, { "sector": sector })} <Link to={"/reservacion/?reservacion=" + data.reservacion}>{data.reservacion}</Link></Typography>
                </Grid>

                <Grid item xs={12} md={7}>
                    {(sector !== "Naranjo") ? null : (
                        <Typography variant={"body1"} className={classes.spacer1}>
                            Le recordamos que la última hora de entrada a Playa Naranjo es a las 2:00 pm. Debe de pasar a la casetilla Santa Rosa a pagar su derecho de admisión antes de dirigirse a la playa.
                        </Typography>
                    )}

                    <Typography variant={"body1"} >

                    </Typography>
                    <List dense={true}>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatMessage({ id: "nj.reg6" })}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatMessage({ id: "rec5" })}
                            />
                        </ListItem>

                    </List>
                    {(sector === "Naranjo") && (

                        <List dense={true}>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg1" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg2" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg3" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg4" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg5" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg6" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg7" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg8" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg9" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg10" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg11" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg12" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg13" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg14" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg15" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg16" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg17" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg18" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg19" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg20" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg21" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg22" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg23" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg24" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg25" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg26" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg27" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg28" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg29" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg30" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg31" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg32" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<strong>{intl.formatMessage({ id: "nj.recomendaciones" })} </strong>} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg33" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg34" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg35" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg36" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg37" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg38" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg39" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg40" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg41" })} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={intl.formatMessage({ id: "nj.nreg42" })} />
                            </ListItem>
                        </List>

                    )}
                </Grid>
                <Grid item xs={12} md={5}>
                    <div className={classes.centred}>
                        <img src={"/images/BANNER-NO-ANIMALES_web.jpg"} alt={"Prohibido el ingreso de mascotas"} />
                    </div>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default Gracias
